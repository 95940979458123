$( document ).ready(function() {
    console.log( "ready!" );
/*
    var fileInput = document.querySelector('input[type=file]');
    var filenameContainer = document.querySelector('#filename');
    var dropzone = document.querySelector('div');

    fileInput.addEventListener('change', function() {
        filenameContainer.innerText = fileInput.value.split('\\').pop();
    });

    fileInput.addEventListener('dragenter', function() {
        dropzone.classList.add('dragover');
    });

    fileInput.addEventListener('dragleave', function() {
        dropzone.classList.remove('dragover');
    });

 */



    $(".plyr__control.plyr__control--overlaid").click(function () {
        $(".video-txt").hide();
    });

});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

;(function($) {

    if (typeof $.fn.tooltip.Constructor === 'undefined') {
        throw new Error('Bootstrap Tooltip must be included first!');
    }

    var Tooltip = $.fn.tooltip.Constructor;

    // add customClass option to Bootstrap Tooltip
    $.extend( Tooltip.Default, {
        customClass: ''
    });

    var _show = Tooltip.prototype.show;

    Tooltip.prototype.show = function () {

        // invoke parent method
        _show.apply(this,Array.prototype.slice.apply(arguments));

        if ( this.config.customClass ) {
            var tip = this.getTipElement();
            $(tip).addClass(this.config.customClass);
        }

    };

})(window.jQuery);